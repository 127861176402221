import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

// index.js

// Suppress specific React development errors
if (process.env.NODE_ENV === 'development') {
    const originalError = console.error;
    console.error = (...args) => {
      if (
        typeof args[0] === 'string' && 
        (args[0].includes('destroy is not a function') ||
         args[0].includes('ReactDOM.render is no longer supported'))
      ) {
        return;
      }
      originalError.apply(console, args);
    };
  
    // Also suppress window error events for the same error
    window.addEventListener('error', (event) => {
      if (event.error?.message?.includes('destroy is not a function')) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  
    // Suppress unhandled promise rejections
    window.addEventListener('unhandledrejection', (event) => {
      if (event.reason?.message?.includes('destroy is not a function')) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }
  
  // Your existing code
  import { createRoot } from 'react-dom/client';
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);

registerServiceWorker();