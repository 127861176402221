import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { Blocks } from 'react-loader-spinner'


const AppSurveyMainLazy = React.lazy(() => {
  if (window.location.pathname !== '/landingPage2') {
    return import('./AppSurveyMain');
  }
  return () => null;
});

const VirtuaaliApuriTemplate = React.lazy(() => {
  return import('./openReactTemplate/VirtuaaliApuriTemplate');
});

export default function SurveyJSReactApplication() {
  React.useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-NP0TGVZXTX"
      },
      {
        trackingId: "AW-10843945043"
      }
    ]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });


    const callback = function () {
      if (typeof (url) !== 'undefined') {
        window.location = url;
      }
    };


    ReactGA.event({
      action: 'conversion',
      category: 'AW-10843945043/1OhpCPrN25EYENPw5bIo',
      label: 'AW-10843945043/1OhpCPrN25EYENPw5bIo',
      event_callback: callback
    });

    ReactGA.event({
      action: 'conversion',
      category: 'AW-10843945043/Qnm4CP3N25EYENPw5bIo',
      label: 'AW-10843945043/Qnm4CP3N25EYENPw5bIo',
      event_callback: callback
    });
  }, []);

  const url = new URL(window.location.href);
  const pages = ['myynti', 'soita', 'demo', 'tarjous', '', 'asennus', 'widgetEditor'];
  const currentPath = url.pathname.replace('/', ''); // Remove leading slash
  
  let virtuaaliApuri = false;
  if (url.hostname === 'localhost' || url.hostname === '127.0.0.1') {
    // Allow any page
    virtuaaliApuri = true;
  } else if (url.hostname === 'virtuaaliapuri.com') {
    // Allow only specific pages
    virtuaaliApuri = true;
    document.title = 'VirtuaaliApuri | Tehosta Myyntiä ja Asiakaspalvelua';
    if (!pages.includes(currentPath)) {
      // Redirect to Google if page is not in 'pages'
      window.location.href = 'https://www.google.fi';
      return;
    }
  }


  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <React.Suspense fallback={<Blocks
            visible={true}
            height="160"
            width="160"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            />}>
            {
              virtuaaliApuri ? (
                <VirtuaaliApuriTemplate />
              ) : 
                <p>Survey not found. Please check survey URL.</p>
            }
          </React.Suspense>
        </Route>
        <Route path="*">
          <React.Suspense fallback={<Blocks
            visible={true}
            height="160"
            width="160"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />}>
            <AppSurveyMainLazy />
          </React.Suspense>
        </Route>
      </Switch>
    </Router>
  )
}


